<template>
  <div class="application">
    <heads></heads>
    <div class="cen_box">
      <ul>
        <li v-for="(item, index) in articleList" :key="index">
          <a :href="item.enclosure" target="_blank"></a>
        </li>

        <li>
          <router-link to="/goods/goodsCategory"></router-link>
        </li>
        <li>
          <router-link to="development"></router-link>
        </li>
      </ul>
    </div>
    <foot></foot>
  </div>
</template>

<script>
import "@css/ico.css";
import "@css/foot.css";
import heads from "@components/head.vue";
import foot from "@components/foot.vue";
import { getArticleList } from "@api/public";

export default {
  components: {
    heads,
    foot,
  },
  props: {},
  data() {
    return {
      backgroud: "",
      articleList: [],
    };
  },
  mounted: function () {
    let that = this;
    getArticleList({ article_category_id: 1 }).then(function (res) {
      that.$set(that, "articleList", res.data.list);
    });
  },
  created: function () {
    document.title = this.$route.meta.title;
  },
};
</script>
<style>
.application>.cen_box {
  background: url(../../assets/images/application.png);
  width: 100%;
  height: 1032px;
  background-size: cover;
  background-position: center 0;
}

.application>.cen_box ul {
  width: 1100px;
  height: 545px;
  position: relative;
  top: 302px;
  left: 343px;
  display: flex;
  justify-content: space-between;
  /* border: 1px solid blue; */
}

.application>.cen_box li {
  width: 100px;
  height: 100%;
  display: flex;
  align-items: center;
  writing-mode: vertical-lr;
  font-family: kaiti;
  box-sizing: border-box;
  /* border: 1px solid red; */
}

.application>.cen_box li a {
  color: #000;
  display: block;
  width: 100%;
  height: 100%;
}

.application>.cen_box li:nth-child(1),
.application>.cen_box li:nth-child(2),
.application>.cen_box li:nth-child(3),
.application>.cen_box li:nth-child(4) {
  margin-left: 25px;
}

.application>.cen_box li:last-child {
  margin-right: 20px;
}
</style>